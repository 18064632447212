import { registerMicroApps, start } from 'qiankun'
import Vue from 'vue'
import App from './App.vue'
// import * as VueRouter from 'vue-router'
Vue.config.productionTip = false

registerMicroApps([
  {
    name: 'admin-ui', // app name registered
    entry: '//localhost:9527',
    container: '#app',
    activeRule: '/admin-ui'
  }
])

start({
  prefetch: false
  // sandbox: {
  //   strictStyleIsolation: true
  // }
})

new Vue({
  render: (h) => h(App)
}).$mount('#micro-app')

// let router = null
// let instance = null
// function render(props = {}) {
//   const { container } = props
//   router = VueRouter.createRouter({
//     base: window.__POWERED_BY_QIANKUN__ ? '/app-vue/' : '/',
//     mode: 'history'
//     // routes
//   })

//   instance = new Vue({
//     router,
//     // store,
//     render: (h) => h(App)
//   }).$mount(container ? container.querySelector('#app') : '#app')
// }

// // 独立运行时
// if (!window.__POWERED_BY_QIANKUN__) {
//   render()
// }

// export async function bootstrap() {
//   console.log('[vue] vue app bootstraped')
// }
// export async function mount(props) {
//   console.log('[vue] props from main framework', props)
//   render(props)
// }
// export async function unmount() {
//   instance.$destroy()
//   instance.$el.innerHTML = ''
//   instance = null
//   router = null
// }
